import { inject } from '@vercel/analytics'
import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import { createHead } from '@unhead/vue'
import * as Sentry from '@sentry/vue'
import Notifications from '@kyvg/vue3-notification'
import router from './routes'
import App from './App.vue'
import { registerClickOutside } from '@/plugins/clickOutside'
import { setLocale } from 'yup'
import { nl } from 'yup-locales'
import './assets/css/style.css'

setLocale(nl)

const app = createApp(App)
const head = createHead()
const pinia = createPinia()

pinia.use(({ store }) => {
  store.router = markRaw(router)
})

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: import.meta.env.MODE,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/api\.spaone\.nl/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

registerClickOutside(app)

app.use(router)
app.use(pinia)
app.use(head)
app.use(Notifications)

app.mount('#app')

inject({
  mode: import.meta.env.MODE,
})
