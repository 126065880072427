import { adminClient as client } from './client'
const resource = '/admin'

export default {
  login: (payload) => {
    return client().post(`${resource}/login`, payload)
  },
  invite: (payload) => {
    return client().post(`${resource}/invite`, payload)
  },
  getInvite: (token) => {
    return client().get(`${resource}/invite/${token}`)
  },
  register: (payload, token) => {
    return client().post(`${resource}/invite/${token}`, payload)
  },
  getCurrent: () => {
    return client().get(`${resource}/user`)
  },
  updateCurrent: (payload) => {
    return client().put(`${resource}/user`, payload)
  },
  getUsers: () => {
    return client().get(`${resource}/users`)
  },
  requestPasswordReset: (payload) => {
    return client().post(`${resource}/reset-password`, payload)
  },
  getResetPassword: (token) => {
    return client().get(`${resource}/reset-password/${token}`)
  },
  resetPassword: (payload, token) => {
    return client().post(`${resource}/reset-password/${token}`, payload)
  },
  delete: (id) => {
    return client().delete(`${resource}/user/${id}`)
  },
  uploadImage: (image) => {
    const formData = new FormData()
    formData.append('file', image)
    return client().post(`${resource}/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
}
