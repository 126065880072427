import { customerClient as client } from './client'
const resource = '/customers'

export default {
  login: (payload) => {
    return client().post(`${resource}/login`, payload)
  },
  register: (payload) => {
    return client().post(`${resource}/register`, payload)
  },
  requestResetPassword: (payload) => {
    return client().post(`${resource}/reset-password`, payload)
  },
  getResetPassword: (token) => {
    return client().get(`${resource}/reset-password/${token}`)
  },
  resetPassword: (payload, token) => {
    return client().post(`${resource}/reset-password/${token}`, payload)
  },
  getCurrent: () => {
    return client().get(`${resource}`)
  },
  updateCustomer: (payload) => {
    return client().put(`${resource}`, payload)
  },
  getSubscriptions: () => {
    return client().get(`${resource}/subscriptions`)
  },
  getReservations: () => {
    return client().get(`${resource}/reservations`)
  },
}
