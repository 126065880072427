import { defineStore } from 'pinia'
import { customerRepository } from '@/repositories'

export const useCustomerStore = defineStore({
  id: 'customer',
  state: () => ({
    customerToken: localStorage.getItem('jwt-customer-token') || null,
    currentUser: null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.customerToken,
  },
  actions: {
    setCustomerToken(token) {
      this.customerToken = token
      localStorage.setItem('jwt-customer-token', token)
    },
    clearCustomerToken() {
      this.customerToken = null
      localStorage.removeItem('jwt-customer-token')
    },
    clearCurrentUser() {
      this.currentUser = null
    },
    async register({
      firstName,
      lastname,
      middleName,
      email,
      phone,
      gender,
      password,
      newsletter,
      address,
    }) {
      try {
        const res = await customerRepository.register({
          firstName,
          lastname,
          middleName,
          email,
          phone,
          gender,
          password,
          newsletter,
          address,
        })
        this.setCustomerToken(res.data.token)

        await this.fetchCurrentUser()
      } catch (error) {
        throw error
      }
    },
    async login({ email, password }) {
      try {
        const res = await customerRepository.login({ email, password })
        this.setCustomerToken(res.data.token)

        await this.fetchCurrentUser()
      } catch (error) {
        throw error
      }
    },
    async register(data) {
      try {
        const res = await customerRepository.register(data)
        this.setCustomerToken(res.data.token)
        await this.fetchCurrentUser()
      } catch (error) {
        throw error
      }
    },
    async fetchCurrentUser(redirect = true) {
      try {
        const res = await customerRepository.getCurrent()
        this.currentUser = res.data
      } catch (error) {
        this.logout(redirect)
      }
    },
    async requestResetPassword({ email }) {
      try {
        await customerRepository.requestResetPassword({ email })
      } catch (error) {
        throw error
      }
    },
    async getResetPassword({ token }) {
      try {
        const res = await customerRepository.getResetPassword(token)
        this.currentUser = res.data
      } catch (error) {
        throw error
      }
    },
    async resetPassword({ password }, { token }) {
      try {
        await customerRepository.resetPassword({ password }, token)
      } catch (error) {
        throw error
      }
    },
    async logout(redirect = true) {
      try {
        this.clearCustomerToken()
        this.clearCurrentUser()
        if (redirect) {
          this.router.push({ name: 'CustomerLogin' })
        }
      } catch (error) {
        throw error
      }
    },
  },
})
