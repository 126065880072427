import { createRouter, createWebHistory } from 'vue-router'
import { useAdminStore } from './stores/adminStore'
import { useCustomerStore } from './stores/customerStore'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Start',
      component: () => import('@/views/Start.vue'),
    },
    {
      path: '/customer',
      name: 'Customer',
      meta: { requiresCustomerAuth: true },
      component: () => import('@/views/customer/Customer.vue'),
    },
    {
      path: '/customer/login',
      name: 'CustomerLogin',
      component: () => import('@/views/customer/Login.vue'),
    },
    {
      path: '/customer/reset-password',
      name: 'CustomerPasswordResetRequest',
      component: () => import('@/views/customer/PasswordResetRequest.vue'),
    },
    {
      path: '/customer/reset-password/:token',
      name: 'CustomerPasswordReset',
      component: () => import('@/views/customer/PasswordReset.vue'),
    },
    {
      path: '/admin/login',
      name: 'AdminLogin',
      component: () => import('@/views/admin/Login.vue'),
    },
    {
      path: '/admin/invite/:token',
      name: 'AdminInvite',
      component: () => import('@/views/admin/Invite.vue'),
    },
    {
      path: '/admin/reset-password',
      name: 'AdminPasswordResetRequest',
      component: () => import('@/views/admin/PasswordResetRequest.vue'),
    },
    {
      path: '/admin/reset-password/:token',
      name: 'AdminPasswordReset',
      component: () => import('@/views/admin/PasswordReset.vue'),
    },
    {
      path: '/admin',
      name: 'Admin',
      meta: { requiresAdminAuth: true },
      component: () => import('@/views/admin/Admin.vue'),
      children: [
        {
          path: '',
          name: 'Admin',
          redirect: '/admin/products',
        },
        {
          path: 'products',
          name: 'Products',
          component: () => import('@/views/admin/products/Products.vue'),
        },
        {
          path: 'products/:id',
          name: 'CategoryProducts',
          component: () =>
            import('@/views/admin/products/CategoryProducts.vue'),
        },
        {
          path: 'products/:id/edit',
          name: 'EditCategory',
          component: () => import('@/views/admin/products/EditCategory.vue'),
        },
        {
          path: 'products/:id/product/:productId',
          name: 'EditProduct',
          component: () => import('@/views/admin/products/EditProduct.vue'),
        },
        {
          path: 'products/:id/collection/:collectionId',
          name: 'EditCollection',
          component: () => import('@/views/admin/products/EditCollection.vue'),
        },
        {
          path: 'products/:id/groups/create',
          name: 'CreateGroup',
          component: () => import('@/views/admin/products/CreateGroup.vue'),
        },
        {
          path: 'products/:id/groups/:groupId',
          name: 'EditGroup',
          component: () => import('@/views/admin/products/EditGroup.vue'),
        },
        {
          path: 'flows',
          name: 'Flows',
          component: () => import('@/views/admin/flows/Flows.vue'),
        },
        {
          path: 'flows/:id',
          name: 'EditFlow',
          component: () => import('@/views/admin/flows/EditFlow.vue'),
        },
        {
          path: 'flows/start',
          name: 'StartFlow',
          component: () => import('@/views/admin/flows/StartFlow.vue'),
        },
        {
          path: 'flows/create',
          name: 'CreateFlow',
          component: () => import('@/views/admin/flows/CreateFlow.vue'),
        },
        {
          path: 'opening-times',
          name: 'OpeningTimes',
          component: () => import('@/views/admin/times/OpeningTimes.vue'),
        },
        {
          path: 'opening-times/default',
          name: 'DefaultTimes',
          component: () => import('@/views/admin/times/DefaultTimes.vue'),
        },
        {
          path: 'opening-times/edit/create',
          name: 'CreateEditedTimes',
          component: () => import('@/views/admin/times/CreateEditedTimes.vue'),
        },
        {
          path: 'opening-times/edit/:id',
          name: 'EditEditedTimes',
          component: () => import('@/views/admin/times/EditEditedTimes.vue'),
        },
        {
          path: 'settings',
          name: 'Settings',
          component: () => import('@/views/admin/Settings.vue'),
        },
        {
          path: 'users',
          name: 'Users',
          component: () => import('@/views/admin/Users.vue'),
        },
      ],
    },
    {
      path: '/flow/:slug',
      name: 'Flow',
      component: () => import('@/views/flow/Flow.vue'),
      children: [
        {
          path: '',
          name: 'FlowIndex',
          component: () => import('@/views/flow/Index.vue'),
        },
        {
          path: ':step',
          name: 'FlowStep',
          component: () => import('@/views/flow/Step.vue'),
        },
        {
          path: 'info',
          name: 'FlowInfo',
          component: () => import('@/views/flow/InfoDetails.vue'),
        },
        {
          path: 'overview',
          name: 'FlowOverview',
          component: () => import('@/views/flow/Overview.vue'),
        },
        {
          path: 'payment',
          name: 'FlowPayment',
          component: () => import('@/views/flow/Payment.vue'),
        },
      ],
    },
    {
      path: '/reservation/success',
      name: 'OrderStatus',
      component: () => import('@/views/OrderStatus.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: () => import('@/views/404.vue'),
    },
  ],
})

router.beforeEach((to) => {
  const adminStore = useAdminStore()
  const customerStore = useCustomerStore()

  if (to.meta.requiresCustomerAuth && !customerStore.isAuthenticated) {
    return '/customer/login'
  }
  if (to.name === 'CustomerLogin' && customerStore.isAuthenticated) {
    return '/customer'
  }
  if (to.meta.requiresAdminAuth && !adminStore.isAuthenticated) {
    return '/admin/login'
  }
  if (to.name === 'AdminLogin' && adminStore.isAuthenticated) {
    return '/admin/products'
  }
})

export default router
