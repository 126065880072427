import { defineStore } from 'pinia'
import { adminRepository } from '@/repositories'

export const useAdminStore = defineStore({
  id: 'admin',
  state: () => ({
    adminToken: localStorage.getItem('jwt-admin-token') || null,
    currentUser: null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.adminToken,
  },
  actions: {
    setAdminToken(token) {
      this.adminToken = token
      localStorage.setItem('jwt-admin-token', token)
    },
    clearAdminToken() {
      this.adminToken = null
      localStorage.removeItem('jwt-admin-token')
    },
    clearCurrentUser() {
      this.currentUser = null
    },
    async invite({ email }) {
      try {
        await adminRepository.invite({ email })
      } catch (error) {
        throw error
      }
    },
    async register({ token, firstname, lastname, password }) {
      try {
        const res = await adminRepository.register(
          {
            firstname,
            lastname,
            password,
          },
          token
        )
        this.setAdminToken(res.data.token)

        await this.fetchCurrentUser()

        this.router.push('/admin/products')
      } catch (error) {
        throw error
      }
    },
    async login({ email, password }) {
      try {
        const res = await adminRepository.login({ email, password })
        this.setAdminToken(res.data.token)

        await this.fetchCurrentUser()

        this.router.push('/admin/products')
      } catch (error) {
        throw error
      }
    },
    async fetchCurrentUser() {
      if (!this.adminToken) {
        return
      }
      try {
        const res = await adminRepository.getCurrent()
        this.currentUser = res.data
      } catch (error) {
        this.logout()
      }
    },
    async logout() {
      this.clearCurrentUser()
      this.clearAdminToken()

      this.router.push({ name: 'AdminLogin' })
    },
  },
})
