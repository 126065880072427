import axios from 'axios'
import { useAdminStore } from '@/stores/adminStore'
import { useCustomerStore } from '@/stores/customerStore'

const isDev = import.meta.env.MODE === 'development'

export const host = isDev ? 'localhost:8080' : 'api.spaone.nl'

export const baseURL = `${isDev ? 'http' : 'https'}://${host}`

export const adminClient = () => {
  const adminStore = useAdminStore()

  return axios.create({
    baseURL,
    headers: {
      Authorization: adminStore.isAuthenticated
        ? `Bearer ${adminStore.adminToken}`
        : '',
    },
  })
}

export const customerClient = () => {
  const customerStore = useCustomerStore()

  return axios.create({
    baseURL,
    withCredentials: true,
    headers: {
      Authorization: customerStore.isAuthenticated
        ? `Bearer ${customerStore.customerToken}`
        : '',
    },
  })
}
